<template>
  <div class="page">
    <div class="nav">
      <HeaderBase :sear="'hide'"/>
    </div>

    <div class="paymoney">
      <div class="head">
        <!-- <h3 class="head_time">请您在
           <span>
            <count-down :Time="datalist.timer"></count-down>
          </span> 内完成支付，否则订单会被自动取消</h3> -->
        <span class="head_paym">
          {{Number(datalist.payAmount.slice(1))>=0?`${$t('m.buy_settleaccounts.Amount_due')}：`:`${$t('m.buy_settleaccounts.Refundable_amount')}：`}}<i class="head_number">
            {{datalist.payAmount}}</i></span>
      </div>
      <div class="body">
        <div class="paytitle">
          {{$t('m.buy_settleaccounts.Payment_Methods')}}
        </div>
        <div class="pay_body">
          <ul class="pay_qu">
            <li class="balancepay"
            :class="isactive=='balan'?'isact':''"
            @click="isactFun('balan')">
              {{$t('m.buy_settleaccounts.Balance_payment')}}
            </li>
            <!-- <li class="unionpay"
            :class="isactive=='union'?'isact':''"
            @click="isactFun('balan')">
              <img src="../../static/imges/unionpay.png" alt="">
            </li> -->
            <li class="wechatpay"
            :class="isactive=='wechat'?'isact':''"
            @click="isactFun('balan')">
              <img src="../../static/imges/wechat.jpg" alt="">
            </li>
          </ul>
          <!-- <ul class="pay_bank" v-if="isactive=='wechat'?false:true">
            <li v-for="i in bankCard" :key="i"></li>
            <span class="add_bank_card">添加银行卡+</span>
          </ul> -->
        </div>
        <div class="wechat_pay_div" v-if="isactive!=='wechat'?false:true">
          <div class="wechat_pay_div_left">
            <p>请使用微信扫码支付</p>
            <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/PhoneApp.png" alt="">
            <span>距离二维码过期还剩<i>40</i>秒</span>
            <span>过期后请刷新页面重新获取二维码</span>
          </div>
          <img src="../../static/imges/wechat_msg.png" alt="">
        </div>
      </div>
      <div class="pay_footer" v-if="isactive=='wechat'?false:true">
        <div class="paytitle">
          {{$t('m.buy_settleaccounts.Payment_password')}}
        </div>
        <div class="pay_input">
          <el-input
            placeholder=" "
            v-model="datalist.passwords"
            type="password"
            maxlength="6"
            minlength="6">
            </el-input>
            <!-- <span class="bank_psw">忘记密码</span> -->
        </div>
        <span class="tishi">{{$t('m.buy_settleaccounts.Please_enter_a_6_digit_payment_PIN')}}</span>

        <el-button class="pay_go" @click="payAmountfun"
          :loading="loadingbtn">{{$t('m.buy_settleaccounts.pay')}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue-demi'
import { toRaw } from 'vue'
// import CountDown from '../../components/CountDown.vue'
import HeaderBase from '../../components/HeaderBase.vue'
import moment from 'moment'
import { ElMessage } from 'element-plus'
import http from '../../api/http.js'
import { setStore, getStore } from '../../tools/storage'

export default {
  components: { HeaderBase },
  name: 'StockUpAccounts',
  data () {
    return {
      isactive: 'balan',
      bankCard: [],
      datalist: {
        passwords: ref(''),
        orderSn: ref(''),
        payAmount: '',
        timer: {
          eventEndTime: '', // 获取存储的时间 订单倒计时
          eventStartTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        }
      },
      capitalAccount: '',
      loadingbtn: false
    }
  },
  methods: {
    isactFun (k) {
      this.isactive = k
    },
    payAmountfun () {
      const loading = this.$loading({
        lock: true,
        text: '支付中'
      })
      // console.log(this.datalist)
      if (/^\d{6}$/.test(this.datalist.passwords)) {
        const passwords = this.datalist.passwords
        // console.log(passwords)
        const capitalAccount = JSON.parse(getStore('usermessage')).capitalAccount
        // console.log(capitalAccount)
        const md5password = this.$md5(`${capitalAccount}${passwords}`)
        const postData = {
          orderSn: toRaw(this.datalist.orderSn),
          password: md5password,
          flag: true
        }
        console.log(postData)
        http.post('/mall-order/order/confirmStockUp', postData, false)
          .then(res => {
            console.log(res)
            if (res.data.flag) {
              ElMessage.success({
                message: res.data.msg
              })
              this.datalist.passwords = ''
              loading.close()
              setStore('payMoneyNumber', this.datalist.payAmount)
              this.$router.push('/paySuccess') // 成功到支付成功页面
            } else {
              // this.$router.push('/personalcenter/myorder') // 失败到我的订单页
              ElMessage.error({
                message: res.data.msg
              })
              loading.close()
            }
          }).catch(err => {
            console.log(err)
          })
        this.loadingbtn = false
      } else {
        loading.close()
        ElMessage.error({ message: '请输入6位支付密码' })
        this.loadingbtn = false
      }
    }
  },
  // props: ['payAmount', 'orderSn'],
  created () {
    const usermessage = getStore('usermessage')
    this.capitalAccount = JSON.parse(usermessage)
    // console.log(getStore('usermessage'))
    this.datalist.payAmount = JSON.parse(decodeURIComponent(this.$route.query.payAmount))
    this.datalist.orderSn = decodeURIComponent(this.$route.query.orderSn)
  },
  mounted () {
  },
  setup () {
    // console.log(route.params)
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
@import './buy.scss';
</style>
